// Libs.
import React from 'react';
// Assets.
import styles from './fresh-hero.module.scss';
//Components.
import FeaturedHeadingPrice from '../common/heading-featured-price/featured-heading-price';

const FreshHero = ({ featured }) => {
  return (
    <div className={styles.freshHero}>
      <FeaturedHeadingPrice content={featured} />
    </div>
  );
};

export default FreshHero;
