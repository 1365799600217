import React from 'react';
import * as PropTypes from 'prop-types';
import { has } from 'lodash';
import styles from './fresh-flyer.module.scss';
import FlyerFullsize from '../common/flyer-fullsize/flyer-fullsize';
import {useFlyerLink} from "../../libs/flyer-link";

const FreshFlyer = ({ content = {flyer: {}} }) => {
  const flyerLink = useFlyerLink();
  return (
    <div className={styles.freshFlyerSection}>
      <FlyerFullsize content={content.flyer} link={content.flyer.link}/>
    </div>
  );
};

FreshFlyer.propTypes = {
  content: PropTypes.object,
};

export default FreshFlyer;
