// Libs
import React, { useMemo } from 'react';
// Assets
import styles from './fresh-store.module.scss';
import FindStoreCta from '../common/find-store-cta/find-store-cta';

const FreshStore = ({ cta }) => {
  return useMemo(
    () => (
      <div className={styles.freshStoreSection}>
        <FindStoreCta cta={cta} />
      </div>
    ),
    [cta]
  );
};

export default FreshStore;
