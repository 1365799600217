// Libs.
import React, {useContext} from 'react';
// Assets.
import styles from './featured-heading-price.module.scss';
import Img from 'gatsby-image';
import * as PropTypes from 'prop-types';
import {AppContext} from "../../layout/AppLayout";

const FeaturedHeadingPrice = ({ content }) => {
  const image = content.image;
  const app = useContext(AppContext);
  return (
    <div className={styles.featuredHeadingPriceSection}>
      <div className={styles.featuredHeadingPriceContent} data-app={app}>
        {content.heading && (
          <h1 className={styles.featuredHeadingPriceHeader} data-app={app}>{content.heading}</h1>
        )}
        {content.price && <p className={styles.featuredHeadingPriceTitle}>{content.price}</p>}
        {content.saving && <p className={styles.featuredHeadingPriceLabel}>{content.saving}</p>}
        {content.description && (
          <p className={styles.featuredHeadingPriceDescription}>{content.description}</p>
        )}
        {content.summary && (
          <p className={styles.featuredHeadingPriceSummary}>{content.summary}</p>
        )}
      </div>
      <div className={styles.featuredHeadingPriceImage}>
        {image && image.fluid && <Img fluid={image.fluid} />}
      </div>
    </div>
  );
};

FeaturedHeadingPrice.defaultProps = {
  content: null,
};

FeaturedHeadingPrice.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    title: PropTypes.string,
    saving: PropTypes.string,
    description: PropTypes.string,
    summary: PropTypes.string,
  }),
};

export default FeaturedHeadingPrice;
