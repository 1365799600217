// Libs.
import React from 'react';
// Deps.
import SEO from '../../components/layout/seo';
import FreshLanding from '../../components/fresh-week/fresh-landing';
import { formatFieldsValue } from '../../libs/formatFromGraph';
import { graphql } from 'gatsby';
import AppLayout from "../../components/layout/AppLayout";
import { getViewingPlatform } from '../../libs/getViewingPlatform';

const FreshThisWeek = ({ data }) => {
    const pageData = formatFieldsValue(data);
    const { iOS, tallDevice } = getViewingPlatform();

    return (
        <AppLayout>
            <SEO title="Fresh This Week" />
            {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}

            <FreshLanding data={pageData} />
        </AppLayout>
    );
};

export default FreshThisWeek;

export const query = graphql`
    query {
        hero: configPagesFreshThisWeek {
            id
            label
            nid: drupal_id
            relationships {
                image: field_hero_image {
                    localFile {
                        childImageSharp {
                            fluid(maxHeight: 800, srcSetBreakpoints: [580, 800]) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            saving: field_hero {
                value
            }
            heading: field_hero_title {
                value
            }
            price: field_hero_price {
                value
            }
            description: field_hero_description {
                value
            }
            summary: field_hero_summary {
                value
            }
        }
        weeklyProducts: configPagesFreshThisWeek {
            id
            label
            nid: drupal_id
            heading: field_weekly_products_title {
                value
            }
            relationships {
                products: field_product_reference {
                    id
                    title
                    description: body {
                        value
                    }
                    price: field_product_price {
                        value
                    }
                    summary: field_product_quality_descriptio {
                        value
                    }
                    packageSize: field_product_amount_weight
                    bonus: field_product_savings {
                        value
                    }
                    points: field_points_awards
                    relationships {
                        image: field_product_images {
                            localFile {
                                childImageSharp {
                                    fluid(maxHeight: 330, srcSetBreakpoints: [330, 330]) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        longosWeeklyProducts: configPagesFreshThisWeek {
            id
            label
            nid: drupal_id
            heading: field_longos_weekly_products_tit {
                value
            }
            relationships {
                products: field_longos_weekly_products {
                    id
                    title
                    description: body {
                        value
                    }
                    price: field_product_price {
                        value
                    }
                    summary: field_product_quality_descriptio {
                        value
                    }
                    packageSize: field_product_amount_weight
                    savings: field_product_savings {
                        value
                    }
                    points: field_points_awards
                    relationships {
                        image: field_product_images {
                            localFile {
                                childImageSharp {
                                    fluid(maxHeight: 330, srcSetBreakpoints: [330, 330]) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        flyer: configPagesFreshThisWeek {
                        id
                        label
                        nid: drupal_id
                        heading: field_weekly_products_title {
                            value
                        }
                        relationships {
                            flyer: field_flyer {
                                id
                                title
                                link: field_flyer_link {
                                    title
                                    uri
                                }
                                relationships {
                                    image: field_flyer_image {
                                        localFile {
                                            childImageSharp {
                                                fluid(quality: 100) {
                                                    ...GatsbyImageSharpFluid
                                                    presentationWidth
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                  }
    }
`;
