// Libs.
import React from 'react';
// Assets.
import styles from './fresh-landing.module.scss';
import { cta, FreshThisWeekLegal } from '../../mocks/fresh-this-week.json';
// Components.
import FreshHero from './fresh-hero';
import Products from '../common/products/products';
import FreshStore from './fresh-store';
import FreshFlyer from './fresh-flyer';
import { ProductVariants } from '../common/cards/product';
import AppHidden from "../app/common/app-hidden";

const FreshLanding = ({ data }) => {
  return (
    <div className={styles.freshLanding}>
      <FreshHero featured={data.hero}/>
      <Products data={data.weeklyProducts} variant={ProductVariants.showPoints}/>
      <Products data={data.longosWeeklyProducts} variant={ProductVariants.showPoints}/>
      <AppHidden>
        <FreshStore cta={cta}/>
        <div className={styles.freshStoreLegal}>
          &ldquo;{FreshThisWeekLegal}&rdquo;
        </div>
      </AppHidden>
      <FreshFlyer content={data.flyer}/>
    </div>
  );
};

export default FreshLanding;
